// codekit-prepend '../../node_modules/jquery/dist/jquery.js'
// codekit-prepend '../../node_modules/bootstrap/dist/js/bootstrap.bundle.js'
// codekit-prepend '../../node_modules/nouislider/distribute/nouislider.js'
// codekit-prepend '../../node_modules/nouislider/distribute/wNumb.js'
// 'fontawesome/fontawesome.js'
// 'fontawesome/brands.js'
// 'fontawesome/solid.js'
// codekit-prepend '../../node_modules/@fortawesome/fontawesome-pro/js/fontawesome.js'
// codekit-prepend '../../node_modules/@fortawesome/fontawesome-pro/js/brands.js'
// codekit-prepend '../../node_modules/@fortawesome/fontawesome-pro/js/solid.js'
import $ from "jquery";
import "bootstrap";
import "popper";
// import "bootstrap/js/dist/util";
import noUiSlider from "nouislider";
import wNumb from "nouislider/distribute/wNumb";
import Player from "@vimeo/player";

$(function () {
  // ----------  Donate Button  ----------
  var baseUrl = "https://give.elic.org/donate?";
  var defaultMinAmount = "10.00";
  var params = {
    "fund_type=": "org",
    "set_frequency=": "Monthly",
  };
  var usdParams = {
    "min_amount=": defaultMinAmount,
    "amount=": defaultMinAmount,
    "selected_fund=": "WLSPG",
    "campaign_id=": "7011M000001V9uoQAC",
    "campaign_name=": "The+Wellspring+%28USD%29",
    "currency=": "USD",
  };
  var cadParams = {
    "min_amount=": defaultMinAmount,
    "amount=": defaultMinAmount,
    "selected_fund=": "XWLSPG",
    "campaign_id=": "7011M000001V9utQAC",
    "campaign_name=": "The+Wellspring+%28CAD%29",
    "currency=": "CAD",
  };
  // var paramsCount = Object.keys(params).length;

  // Get / Build the URL
  function getURL(cParams) {
    var arr = [];

    // shared params
    Object.keys(params).forEach(function (key) {
      arr.push(key + params[key]);
    });

    // USD or CAD params
    Object.keys(cParams).forEach(function (key) {
      // set the amount param from the slider
      if (key === "amount=") {
        arr.push(key + $("#sliderAmountInput").val().replace(/[$,]/g, ""));
      } else {
        arr.push(key + cParams[key]);
      }
    });

    var url = baseUrl + arr.join("&");
    $(".btn-donate").attr("href", url);
  }

  // Initial donate button url = USD
  getURL(usdParams);

  // USD Button
  $(".btn-group-toggle #btn-usd").on("click", function () {
    getURL(usdParams);
  });
  // CAD Button
  $(".btn-group-toggle #btn-cad").on("click", function () {
    getURL(cadParams);
  });

  // ----------  noUISlider  ----------
  var slider = document.getElementById("slider");
  var sliderAmountInput = document.getElementById("sliderAmountInput");
  var sliderMax = 500;
  var sliderStep = 5;

  noUiSlider.create(slider, {
    start: [65],
    step: sliderStep,
    connect: [true, false],
    range: {
      min: [10],
      max: [sliderMax],
    },
    format: wNumb({
      decimals: 0,
      prefix: "$",
      // thousand: ','
    }),
  });

  slider.noUiSlider.on("update", function (values, handle) {
    var inputVal = sliderAmountInput.value.replace(/[$,]/g, "");

    if (Number(inputVal) <= sliderMax) {
      sliderAmountInput.value = values[handle];
    } else {
      if (!~sliderAmountInput.value.indexOf("$")) {
        sliderAmountInput.value = "$" + sliderAmountInput.value;
      }
    }

    // update the donate button url
    var btnID = $(".btn-group-toggle").find(".active").attr("id");
    if (btnID === "btn-usd") {
      getURL(usdParams);
    }
    if (btnID === "btn-cad") {
      getURL(cadParams);
    }
  });
  slider.noUiSlider.on("slide", function (values, handle) {
    var inputVal = sliderAmountInput.value.replace(/[$,]/g, "");

    if (Number(inputVal) > sliderMax) {
      sliderAmountInput.value = values[handle];
    }
  });

  // Listen to keydown events on the input field.
  sliderAmountInput.addEventListener("change", function () {
    slider.noUiSlider.set(this.value);
  });

  sliderAmountInput.addEventListener("keydown", function (e) {
    var value = slider.noUiSlider.get().replace(/[$,]/g, "");
    value = Number(value);
    var steps = slider.noUiSlider.steps();
    // [down, up]
    var step = steps[0];
    var position;
    var inputVal;

    switch (e.which) {
      // 13 is enter,
      case 13:
        slider.noUiSlider.set(this.value);
        break;

      // 38 is key up,
      case 38:
        // Get step to go increase slider value (up)
        position = step[1];

        // false = no step is set
        if (position === false) {
          position = 1;
        }

        inputVal = this.value.replace(/[$,]/g, "");
        if (position === null && inputVal >= sliderMax) {
          this.value = "$" + (Number(inputVal) + Number(sliderStep));
          slider.noUiSlider.set(this.value);
          break;
        }

        // null = edge of slider
        if (position !== null) {
          slider.noUiSlider.set(value + position);
        }

        break;

      // 40 is key down.
      case 40:
        position = step[0];

        if (position === false) {
          position = 1;
        }

        inputVal = this.value.replace(/[$,]/g, "");
        if (inputVal > sliderMax) {
          this.value = "$" + (Number(inputVal) - Number(sliderStep));
          slider.noUiSlider.set(this.value);
          break;
        }

        if (position !== null) {
          slider.noUiSlider.set(value - position);
        }

        break;
    }
  });

  // ----------  Smooth Scrollto  ----------
  $(function () {
    $('a[href*="#"]:not([href="#"]):not([data-toggle="collapse"]):not(.nav-tabs a):not(.carousel-control):not(.wfTab):not([data-toggle="modal"])').on("click", function () {
      if (location.pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "") && location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            400
          );
          return false;
        }
      }
    });
  });

  // ============= Video Modal =============
  // when the modal is opened autoplay it
  $(".modal").on("shown.bs.modal", function (e) {
    var iFrame = $(this).find("iframe");
    if (iFrame.length) {
      var player = new Player(iFrame[0]);
      player.play();
    }
    // var iframe = document.querySelector("iframe");
    // var player = new Vimeo.Player(iframe);
    // player.play().then(function () {
    //   // the video was played
    // }).catch(function (error) {
    //   switch (error.name) {
    //     case "PasswordError":
    //       // the video is password-protected and the viewer needs to enter the
    //       // password first
    //       break;
    //     case "PrivacyError":
    //       // the video is private
    //       break;
    //     default:
    //       // some other error occurred
    //       break;
    //   }
    // });
  });

  // stop playing the vimeo video when the modal is closed
  $(".modal").on("hide.bs.modal", function (e) {
    var iFrame = $(this).find("iframe");
    if (iFrame.length) {
      var player = new Player(iFrame[0]);
      player.pause();
    }
  });

  // Open modal from URL hash. Ex: /#box4VideoModal
  $(".modal").each(function () {
    var modalId = `#${$(this).attr("id")}`;
    if (window.location.href.indexOf(modalId) !== -1) {
      $(modalId).modal("show");
    }
  });

  // ----------  Close modal, scroll to Donate section  ----------
  $(".modal-footer .btn, .modal a[data-toggle='modal']").on("click", function () {
    $(".modal").modal("hide");
    // $(this).closest('.modal').modal('hide');
    // $('.modal').on('shown.bs.modal', function (e) {
    //   $(this).focus();
    // })
  });
});